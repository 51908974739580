$icons: (
  play: url("#{$base-theme-path}/img/icons/src/new-play-icon.svg"),
);

.svg-icon-container {
  position: relative;
  display: block;
  width: inherit;
  height: 28px;
}

.svg-search-icon {
  position: relative;
  padding: 1px 6px;
  height: 24px;
  width: 24px;
}
