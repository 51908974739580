// Common styles used by all icons in HTML and CSS
@mixin svg-icon {
  color: $color-darker-grey;
  display: block;
  fill: $color-darker-grey;
  height: 35px;
  width: 35px;
}

@mixin icon($icon: $icon, $pseudo: before) {
  &::#{$pseudo} {
    content: map-get($icons, $icon);
  }
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: url('#{$base-theme-path}img/icons/src/#{$filename}') no-repeat;
  mask: url('#{$base-theme-path}img/icons/src/#{$filename}') no-repeat;
}
